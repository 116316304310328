.orderDetail {
  &_container {
    height: auto;
    width: 100%;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    background-color: #EFEFF4 !important;
    & > div {
      width: 100% !important;
    }
  }
  &_tabs {
    & > div > div > span {
      background-color: #313C52 !important;
    }
  }
  &_tab{
    & > span > svg {
      color: #313C52 !important;
    }
  }
}
