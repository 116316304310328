.settings {
  overflow-y: auto !important;
  max-height: 90vh;
  font-size: 20px;
  &__title {
    font-size: 1.4em;
  }
  &_list {
    background-color: #fff;
  }
  &__card {
    width: 450px !important;
    margin: 0 0.4em 0.4em 0.4em;
    list-style-type: none !important;
  }
  &__cardMobile {
    width: 100% !important;
    margin: 1em;
  }
  &__body {
    font-size: 0.8em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100% !important;
    & > div,
    li,
    ul {
      max-height: 92px !important;
      width: 286px !important;
    }
    &__configuration {
      font-size: 0.8em;
    }
  }
  &_container {
    height: 80vh !important;
    display: grid;
    grid-template-rows: 14% 86%;
    padding: 2em 4em;
  }
  &_browser {
    width: 60% !important;
    margin: auto;
    display: grid !important;
    grid-template-columns: 50% 50%;
    & > div {
      height: 80vh;
    }
  }
  &_rightBar {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-self: flex-end;
  }
  &_status {
    display: flex;
    flex-direction: column;
  }
  &_general {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90% !important;
    margin: 0 auto !important;
    & > div {
      min-height: 12vh;
      max-height: 75vh;
      overflow-y: auto;
    }
  }
}

@media (max-width: 600px) {
  .settings_container {
    padding: 0;
  }
}
