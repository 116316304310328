.scrollTop_btn {
  position: fixed !important;
  bottom: 12vh;
  right: 6vw;
  width: auto;
  height: auto;
  z-index: 1031 !important;
  &:focus,
  &:hover,
  &:active {
    outline: none !important;
  }
  & > button > span > div > svg {
    color: #ffffff !important;
    font-size: 32px !important;
  }
  & > button > span > svg {
    color: #ffffff !important;
    font-size: 32px !important;
  }
}
