.checkout {
  &_drawer {
    & > div {
      &:last-child {
        border-radius: 0 0 8px 8px !important;
      }
    }
  }
  &_drawerUp {
    & > div {
      &:last-child {
        border-radius: 8px 8px 0 0 !important;
      }
    }
  }
}
