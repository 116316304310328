.posMobile {
  position: absolute !important;
  width: 100vw;
  height: auto !important;
  background-color: #efeff4 !important;
  &_tabs {
    height: 10% !important;
    border-radius: 1.2em 1.2em 0 0 !important;
    box-shadow: 0 1px 6px #00000029 !important;
    & > .active {
      & > span > svg,
      span {
        color: #313c52 !important;
      }
    }
    & > a {
      padding: 1.4em 0 !important;
      & > span > svg,
      span {
        color: #b6bec6 !important;
      }
      &:active {
        & > span > svg,
        span {
          color: #b6bec6 !important;
        }
      }
    }
  }
}

.main_swipeable_routes {
  & > div {
    height: 100%;
  }
}
