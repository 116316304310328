.advancedOne {
  &_card {
    width: 100% !important;
    min-height: 92px !important;
    height: auto !important;
    background-color: #ffffff !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    & > div {
      width: 100% !important;
    }
    &_title {
      text-align: start !important;
      font-size: 20px !important;
      font-weight: 600;
    }
    &_subtitle {
      color: #b6bec6 !important;
    }
    &_label {
      text-align: right !important;
      color: #64707a !important;
      font-size: 20px !important;
      font-weight: 600;
    }
    &_extra {
      font-size: 15px !important;
      text-align: left !important;
      max-width: 98% !important;
      color: #64707a !important;
    }
    &_footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &_btn {
    width: 100% !important;
    height: 100% !important;
    padding: 1.4em !important;
    &:focus,
    &:active,
    &:active {
      outline: none !important;
    }
  }
  &_detail {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    height: 100% !important;
    & > div {
      width: 50% !important;
      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > div {
          display: flex;
          width: 100%;
        }
      }
      &:last-child {
        & > div {
          display: flex;
          width: 100%;
          flex-direction: row-reverse;
        }
      }
    }
  }
}
