.productMobile {
  width: 100% !important;
  height: auto !important;
  &_list {
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;
    &_item {
      width: 100% !important;
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px !important;
    }
  }
}
