.loginm {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  &_upground {
    height: 40% !important;
    background-color: #ffffff !important;
  }
  &_cards {
    height: 100% !important;
    position: absolute;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_card {
    width: 80%;
    height: auto;
    padding: 1em !important;
    & > img {
      height: 16vh;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
  }
}
