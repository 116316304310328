@import '../settings/variable.scss';
.cartTotal > hr {
  margin-top: 0;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1) !important;
}

.payment {
  outline: none !important;
  & > div:focus-within {
    outline: none !important;
  }
  & > button:focus-within {
    outline: none !important;
  }
  button:focus {
    outline: none !important;
  }
  @include transitionAll();
  & > div {
    @include transitionAll();
  }
  background-color: #fff;
  margin: 1px 0px;
  max-width: 100% !important;
}

.payment__purchase {
  a {
    width: 100%;
    margin: 5px;
  }
}

.payment__sub-total {
  font-weight: bolder;
}

.payment__total {
  margin: 0px;
}

.payment__discount-delete {
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff1535;
}

.payment__discount-text {
  text-align: center;
}

.payment__total-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.payment__total {
  font-weight: bold !important;
  font-size: 18px !important;
  & span > div > span {
    &:first-child {
      font-size: 0.8em !important;
      align-self: flex-end;
      color: $color-grey-heavy;
    }
    &:last-child {
      font-size: 1.6em !important;
    }
  }
}

.payment__mount {
  padding-right: 0.8em;
  text-align: right;
}
