.genericButton {
  width: 100%;
  padding: 0.6em !important;
  border-radius: 8px !important;
  font-weight: 600;
  color: #ffffff !important;
  &:focus {
    outline: none !important;
  }
}
