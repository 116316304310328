.FloatMobile {
  &_container {
    position: fixed !important;
    bottom: 4vh;
    right: 6vw;
    width: auto;
    height: auto;
    z-index: 1040 !important;
  }
  &_btn {
    &:focus,
    &:hover,
    &:active {
      outline: none !important;
    }
  }
}
