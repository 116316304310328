#cart {
  height: 80vh !important;
  padding: 0 1.4em 1.4em 1.4em !important;
}

.cartListCollapse {
  height: 100% !important;
  max-height: 67vh;
  display: flex;
  flex-direction: column;
  transition: max-height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cart {
  &-title {
    max-width: 80px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    font-size: 1.4rem;
  }
  &_horizontal {
    & > div > ul {
      display: flex !important;
      flex-direction: row-reverse !important;
      & > span {
        margin: 0 0.4em !important;
      }
    }
  }
  &_controls {
    &_list {
      width: 120px !important;
    }
    width: 30% !important;
    & > div {
      border-radius: 0.6em;
      max-width: none !important;
    }
    & > div > ul {
      display: flex;
      flex-direction: column;
      max-height: 370px !important;
      flex-wrap: wrap !important;
      overflow-y: hidden !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      & > span {
        max-width: 60px !important;
        height: 60px !important;
      }
      & > span > button {
        outline: none !important;
        width: 60px;
        height: 60px;
        padding: 0.4em;
        font-size: 1.5rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:focus {
          outline: none !important;
        }
        &:active {
          outline: none !important;
        }
      }
    }
  }
  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &_container {
    &_list {
      height: 100% !important;
      min-height: 25vh !important;
      max-height: 75vh;
      display: flex;
      flex-direction: column;
      transition: max-height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  &_input {
    text-align: right;
    border-radius: 2em;
    color: $comercia-blue;
    border: solid 1px $comercia-blue;
    outline: none !important;

    &_price {
      width: 68%;
      padding: 0.5em;
    }

    &_qty {
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > input {
        border: none;
        padding: 0.5em 0;
        text-align: center;
        border-radius: 2em;
        color: $comercia-blue;
        appearance: textfield;
        width: 40% !important;
        outline: none !important;

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      & > button {
        padding: 0;
        width: 20px;
        height: 20px;
        border: none;
        display: flex !important;
        outline: none !important;
        border-radius: 50% !important;
        background-color: $comercia-blue-disabled;
        justify-content: center;
        align-items: center;
        line-height: 0px !important;
        color: #fff;

        & > svg {
          font-weight: 600 !important;
          font-size: 1.2em !important;
        }

        &:focus {
          outline: none !important;
        }

        &:hover {
          background-color: $comercia-blue;
        }
      }
    }
  }

  &_list {
    &_expandible {
      width: 100% !important;
      display: table-row;

      & > div {
        width: 100% !important;
      }
    }

    &_table {
      & > tbody > tr:focus-within {
        background-color: rgb(238, 238, 238);
        -webkit-transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        -ms-transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      table-layout: fixed !important;

      & > tbody,
      thead > tr > td,
      th {
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
        transition-timing-function: ease-in-out;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
      }

      & > tbody > tr > td:first-child {
        width: 36% !important;
      }

      & > thead > tr > th:first-child {
        width: 36% !important;
      }

      & > tbody > tr > td:last-child {
        width: 8% !important;
      }

      & > thead > tr > th:last-child {
        width: 8% !important;
      }

      // & > thead > tr > th {
      //   width: 15% !important;
      //   -webkit-transition: all 1s;
      //   -moz-transition: all 1s;
      //   -o-transition: all 1s;
      //   transition: all 1s;
      //   transition-timing-function: ease-in-out;
      //   -moz-transition-timing-function: ease-in-out;
      //   -webkit-transition-timing-function: ease-in-out;
      //   -o-transition-timing-function: ease-in-out;
      // }
    }
  }

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

  &__preview-img {
    width: 50px;
    height: 45px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    padding: 5px;
    position: absolute;
    top: 8px;

    & + span {
      padding-left: 60px;
      display: block;
      /* min-width: 400px;*/
    }

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }

  &__increase-decrease-product {
    border: none;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #f0ebeb;
    }
  }

  &__table-btn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $color-red-hover;
    position: absolute;
    top: 0px;
    left: 0px;

    &:hover {
      color: $color-red-hover;

      svg {
        fill: $color-red-hover;
      }
    }
  }

  &__lite {
    width: 100%;
    padding: 10px 15px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
    height: 60px;
    position: relative;
  }

  &__lite-fix-card-body {
    padding: 0px;
  }

  &__lite-preview-img {
    position: relative;

    img {
      width: 40px;
      object-fit: cover;
      margin-right: 10px;
      height: 40px;
      object-position: center top;
    }
  }

  &__lite-name {
    font-weight: 500;
    max-width: 52vw;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__lite-quantity {
    position: absolute;
    top: -20px;
    left: -12px;
    background-color: #e0e0e0;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
  }

  &__lite-price {
    font-weight: bold;
    float: right;
    margin-top: 5px;
    font-size: 16px;
  }

  &__lite-delete {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #dc3545;
    color: #fff;
    border: none;
    font-size: 15px;
    font-weight: bold;
  }

  &__lite-options {
    padding: 13px 40px;
    border: none;
    vertical-align: bottom;
    margin: 0px 7px;
  }

  &__lite-input {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    width: 15%;
    height: 41px;
    text-align: center;
    vertical-align: bottom;
    border: none;
  }

  &__lite-increaseProduct {
    background-color: #d4edda;
  }

  &__lite-decreaseProduct {
    background-color: #f8d7da;
  }
}

.PaperCartList {
  padding-top: 0.5em !important;
}

.tRow-fix {
  cursor: pointer;
  & > td {
    text-align: center;
    padding-top: 0.4em !important;
    padding-bottom: 0.4em !important;
    & > input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & > input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    & > input[type="number"] {
      -moz-appearance: textfield;
      /* Firefox */
    }
  }
}

.cart-state {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
}

.cart-state__order {
  width: 20% !important;
  display: inline-flex;
  align-content: center;
  align-items: center;
  min-height: 48px !important;
}

.cart-state__order > * {
  margin: 0px 5px;
}

.cart-state__order > *:first-child {
  margin-left: 0px;
}

.cart-state__order svg {
  font-size: 25px;
}

.cart-state__order span {
  font-size: 20px;
}

.cart-state__order #order-status {
  border: solid 1px #000;
  text-align: center;
}

.cart-state-__container {
  display: flex;
  align-items: center;
  min-height: 8% !important;
  padding: 0.4em 0.6em 0.4em 1.6em !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.cart-state__email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.cart-state__emails-container {
  display: inline-flex;
  width: 100% !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;

  & > div {
    margin: 5px 10px;
  }
}

.cartTotal {
  &_container {
    display: grid;
    width: 100% !important;
    grid-template-columns: 60% 40%;
  }

  &_details {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
  }
}

#overflow-comercia {
  overflow: hidden auto;
  scroll-behavior: smooth !important;
  transition: height 0.3s linear, line-height 0.3s linear, opacity 0.5s linear;
  & > table {
    transition: height 0.3s linear, line-height 0.3s linear, opacity 0.5s linear;

    & > tbody {
      transition: height 0.3s linear, line-height 0.3s linear, opacity 0.5s linear;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5em;
    background-color: #f5f5f5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    width: 0.4em;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5em;
    background-color: $comercia-purple;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.checkOutButton {
  border-radius: 0.6em !important;
  background-color: $comercia-purple !important;
  min-height: 10vh !important;
  max-height: 12vh !important;
  max-height: 12vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $comercia-purple-disabled !important;
  }

  & > span {
    display: grid;
    grid-template-rows: 70% 30%;
    font-weight: 600;
    font-size: 1.1em;
  }
}

.checkOutButton_save {
  border-radius: 0.6em !important;
  background-color: $color-gray !important;
  min-height: 10vh !important;
  max-height: 12vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $color-additional !important;
  }

  & > span {
    display: grid;
    grid-template-rows: 70% 30%;
    font-weight: 600;
    font-size: 1.1em;
    & > svg {
      font-size: 1.8em !important;
      align-self: center !important;
      justify-self: center !important;
    }
  }
}

.checkOutButton_deliver {
  border-radius: 0.6em !important;
  background-color: $color-blue !important;
  min-height: 10vh !important;
  max-height: 12vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $color-blue !important;
  }

  & > span {
    display: grid;
    grid-template-rows: 70% 30%;
    font-weight: 600;
    font-size: 1.1em;
  }
}

.checkOutButton_saveEdition {
  border-radius: 0.6em !important;
  background-color: $color-blue !important;
  min-height: 10vh !important;
  max-height: 12vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $color-blue !important;
  }

  & > span {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1.1em;
    color: #fff !important;

    & > svg {
      font-size: 0.8em;
      margin: auto;
      color: #fff !important;
      fill: #fff;
    }

    & > span > span,
    svg {
      color: #fff !important;
      fill: #fff;
    }

    & > span > span {
      color: #fff !important;
    }
  }
}

.checkOutButton_cotizar {
  border-radius: 0.6em !important;
  background-color: $color-blue !important;
  min-height: 10vh !important;
  max-height: 12vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $color-blue !important;
  }

  & > span {
    display: grid;
    grid-template-rows: 70% 30%;
    font-weight: 600;
    font-size: 1.1em;

    & > svg {
      font-size: 1.1em;
      margin: auto;
    }

    & > span {
      width: 100%;
    }
  }
}

.checkOutButton_timbrar {
  border-radius: 0.6em !important;
  background-color: $color-yellow !important;
  min-height: 4vh !important;
  outline: none !important;

  & > div:focus-within {
    outline: none !important;
  }

  & > button:focus-within {
    outline: none !important;
  }

  button:focus {
    outline: none !important;
  }

  &:disabled {
    background-color: $color-yellow !important;
  }

  & > span {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1.1em;

    & > svg {
      font-size: 0.8em;
      margin: auto;
      color: #fff !important;
      fill: #fff;
    }

    & > span > span,
    svg {
      color: #fff !important;
      fill: #fff;
    }

    & > span {
      color: #fff !important;
    }
  }
}

.posCartCollapse {
  & > div {
    height: 100% !important;
  }
}

.alertRow {
  background-color: $color-red;

  & > td {
    color: #fff !important;
  }
}

.menu {
  &_share {
    &_option {
      min-width: 20vw !important;
    }
  }
}

.vertical_divider {
  margin: auto 0.6em;
  width: 1px;
  height: 60%;
  background-color: #e0e0e0;
}

.maxToolTip {
  max-width: 150px !important;
}

@media (max-width: 600px) {
  .cart__lite-fix-card-body {
    max-height: 54vh !important;
    overflow-y: auto;
    border-radius: 0 0 1em 1em !important;
  }

  .cartTotal {
    &_container {
      background-color: #fff !important;
      padding: 0.6em 1em;
      width: 100%;
      position: fixed;
      bottom: 7vh;
      box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.5);
      border-radius: 0.4em !important;
      grid-template-columns: 50% 50%;
    }
  }

  .checkOutButton_deliver {
    & > span > span > svg {
      font-size: 1.4em !important;
    }
  }

  .checkOutButton {
    & > span > span > svg {
      font-size: 1.4em !important;
    }
  }
}

@media (max-height: 800px) {
  #cart {
    height: 70vh !important;
  }

  .cartTotal_container {
    height: 100px !important;
    grid-template-columns: 50% 50%;
  }

  .table__container,
  .grid__container {
    height: 70vh !important;
  }

  .product-grid {
    height: 64vh !important;
  }

  .grid-overflow {
    height: 58vh !important;
  }

  #overflow-comercia {
    max-height: 42vh !important;
  }

  .cart_container_list {
    max-height: 54vh !important;
  }

  .cartListCollapse {
    max-height: 42vh !important;
  }

  .cart_list_table {
    & > tbody > tr > td:last-child {
      width: 10% !important;
    }

    & > thead > tr > th {
      font-size: 0.9em !important;
    }

    & > tbody > tr > td {
      font-size: 0.9em !important;
    }

    & > thead > tr {
      height: 42px !important;
    }

    & > tbody > tr > th {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }
  }

  .cart-state-__container {
    min-height: 6% !important;
    padding: 0.2em 1.2em !important;
  }

  .cart-state__order {
    width: 20% !important;
    & > svg {
      font-size: 1.2em !important;
    }

    & > div > div > div > span {
      font-size: 1.2em !important;
    }

    & > div > div > div > button {
      height: 100% !important;
    }

    & > div > div > div > button > span {
      font-size: 1.6em !important;
    }
  }

  .cart_controls {
    width: 16% !important;

    & > button > span > svg {
      font-size: 0.8em !important;
    }

    & > button {
      padding: 8px !important;
    }
  }
}

.chip_instock {
  height: 28px !important;
  border: 1px solid #ffffff !important;
  & > span {
    color: #ffffff !important;
  }
}
