.login {
  &_container {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &_img {
    width: 100% !important;
    height: 25% !important;
    // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    & > img {
      width: 300px !important;
    }
  }
  &_form {
    width: 100%;
    z-index: 20;
    height: 75% !important;
    & > div {
      margin-top: 0.8em !important;
    }
    & > div > span {
      font-size: 1.4em !important ;
    }
  }
}

@media (min-width: "1200px") {
  .login_img {
    width: 400px !important;
    height: 10vh !important;
    margin: auto;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  }
  .login_container {
    height: auto;
    margin-top: 8em;
    position: relative;
  }
  .login_form {
    width: 400px !important;
    margin: 0px auto;
  }
}
