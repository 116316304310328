.listItemG-container{
   // min-height: 86px;
   background-color: #fff;
}
.expand__body{
    overflow: hidden;
    height: auto;
    transition: max-height 0.5s ease-out;
    // padding-top: 2px;
    max-height: 600px;
}
.hideDiv{
    display: none !important;
}
.listItemG-text-primary{
    color: #313C52 !important;
    font-size: 20px !important;
}
.listItemG-text-primary2{
    color: #64707A !important;
    font-size: 20px !important;
}
.listItemG-text-secondary{
    color: #B6BEC6 !important;
    font-size: 13px !important;
}