.orderMobile {
  width: 100% !important;
  height: auto !important;
  &_list {
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;
  }
  &_item {
    width: 100% !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px !important;
  }
  &_contained {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    & > div {
      width: 100% !important;
      padding: 0.4em 1em !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        font-size: 14px !important;
        display: flex;
        align-items: center;
        &:first-child {
          color: #313c52 !important;
          font-family: 'Roboto Medium';
        }
        &:last-child {
          color: #64707a !important;
        }
      }
      &:nth-child(odd) {
        background-color: #f6f6f7 !important;
      }
    }
  }
}
