.flag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  &_circular_white {
    color: #313C52 !important;
  }
  &_circular_gray {
    color: gray !important;
  }
  &:active, &:focus {
    outline: none !important;
  }
}
