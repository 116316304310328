.mda {
  &_list {
    & > li {
      border-bottom: solid 1px #efeff4;
      background-color: #fff !important;
      &:last-child {
        border-bottom: none !important;
      }
    }
    & > li > div > svg {
      font-size: 24px !important;
    }
    &_meth {
      font-size: 16px;
      color: #313c52;
    }
    &_acco {
      font-size: 16px;
      color: #64707A;
    }
    &_detail {
      display: flex !important;
      flex-direction: column !important;
      height: auto !important;
      line-height: 1.4em !important;
      width: 100% !important;
    }
    &_mont {
      font-size: 18px;
      color: #313c52;
      text-align: left !important;
      font-family: "Roboto Bold" !important;
    }
    &_submont {
      font-size: 0.9rem;
      color: #313c52;
      text-align: left !important;
    }
    &_date {
      font-size: 13px;
      color: #B6BEC6;
      width: 28%;
      text-align: right !important;
      margin-right: 1em !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
    }
    &_now {
      font-size: 13px;
      color: #646f79;
    }
  }
  &_modal {
    &_body {
      padding: 0 1rem !important;
    }
  }
  &_drop_button {
    &:focus {
      outline: none !important;
    }
    &:hover {
      outline: none !important;
    }
    &:active {
      outline: none !important;
    }
  }
  &_product {
    &_detail {
      height: 100% !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }
  }
  &_fromTo {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  &_drop {
    transition: transform 0.4s !important;
    transform: rotate(180deg);
  }
  &_dots {
    &_fr {
      border-radius: 50% !important;
      width: 8px !important;
      height: 8px !important;
      background-color: #b6bec6 !important;
    }
    &_bl {
      height: 12px !important;
      width: 3px !important;
      background-color: #64707a !important;
      margin-left: 2px !important;
    }
    &_to {
      border-radius: 50% !important;
      width: 8px !important;
      height: 8px !important;
      background-color: #313c52 !important;
    }
  }
  &_black {
    height: 10px;
  }
}

.noDropDown {
  transform: rotate(0deg);
  transition: transform 0.4s !important;
}

.mda_list_el{
  background-color: white;
    padding: 10px !important;
}
