.inputIconButton {
  outline: none !important;
  font-size: 1.5rem !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center;
  &:focus {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
}
