.detailFooter {
  width: 100vw;
  position: fixed;
  bottom: 0 !important;
  background-color: #ffffff !important;
  z-index: 2;
  box-shadow: 0px 1px 6px #00000029 !important;
  &_o {
    overflow-x: scroll;
    width: 100%;
    padding: 0.6em;
    opacity: 1;
  }
  &_c {
    width: 0% !important;
    padding: 0em !important;
    opacity: 0 !important;
    filter: alpha(opacity=0);
  }
  &_short {
    width: 100%;
    height: auto !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & > div {
      display: flex;
      height: 100%;
      -webkit-transition: opacity, width 0.4s cubic-bezier(.42,0,.21,.99) !important;
      -moz-transition: opacity, width 0.4s cubic-bezier(.42,0,.21,.99) !important;
      -o-transition: opacity, width padding 0.4s cubic-bezier(.42,0,.21,.99) !important;
      transition: opacity, width 0.4s cubic-bezier(.42,0,.21,.99) !important;
      &:first-child {
        flex-direction: column;
        align-items: flex-end !important;
      }
      &:last-child {
        width: 100%;
        flex-direction: row-reverse;
      }
    }
  }
  &_row {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.4em !important;
    & > span {
      font-size: 13px;
      &:first-child {
        font-size: 16px;
      }
    }
  }
  &_total {
    font-weight: 600;
    font-size: 20px !important;
  }
  &_btn {
    height: 75px !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    outline: none !important;
    &:disabled {
      opacity: 0.5 !important;
    }
    & > svg {
      margin-bottom: 0.4em !important;
    }
    & > svg,
    span {
      color: #ffffff !important;
    }
  }
}
