.inputText {
  & > input {
    width: 90% !important;
    height: 100% !important;
    border: none !important;
  }
  &_icon {
    width: 10% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 0.8em !important;
  border-radius: 20em !important;
  background-color: #ffffff;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
