.fde {
  &_list {
    & > li {
      height: 75px !important;
      padding: 0 !important;
      background-color: white;
    }
    &_icon {
      width: 12%;
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        font-size: 20px !important;
        color: #ffffff;
      }
      &_in {
        background-color: #23A1DB !important;
      }
      &_out {
        background-color: #F05B70 !important;
      }
    }
    &_meth {
      font-size: 16px;
      color: #313c52;
    }
    &_acco {
      font-size: 13px;
      color: #646f79;
    }
    &_mont {
      font-size: 20px;
      color: #313c52;
      text-align: right !important;
      font-family: "Roboto Bold" !important;
    }
    &_date {
      font-size: 13px;
      color: #646f79;
      text-align: right !important;
    }
  }
}


.horiz-menu ul {
  display: flex !important;
}

.horiz-menu ul>li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 45px;
  color: #65707a;
  font-size: 14px;
}
