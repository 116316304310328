.listItemImage {
  &_child {
    width: 100%;
    height: 92px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_detail {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
      height: auto;
      display: flex;
      text-align: left !important;
      color: #313c52;
    }
  }
  &_title {
    font-size: 18px !important;
    width: 60vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_subtitle {
    margin-top: 6px !important;
    font-size: 16px !important;
    font-weight: 600;
  }
  &_count {
    margin-top: auto !important;
    align-items: center !important;
    & > div {
      font-size: 16px !important;
    }
    &_number {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px !important;
      width: auto;
      color: #ffffff !important;
      background-color: #b6bec6 !important;
      border-radius: 6px !important;
    }
    &_title {
      margin-left: 0.4em !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #b6bec6 !important;
    }
  }
  &_image {
    background-color: #efeff4;
    width: 96px !important;
    height: 96px !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }
  &_badge {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    background-color: #23A1DB;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    right: 0;
    top: 0;
    & > svg {
      color: #ffffff !important;
    }
  }
  &_button {
    width: 100%;
    padding: 16px !important;
    background-color: #ffffff !important;
    &:focus,
    &:hover,
    &:active {
      outline: none !important;
    }
  }
  &_card {
    width: 100%;
    padding: 16px !important;
    background-color: #ffffff !important;
  }
}
