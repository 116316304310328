.inputNumber {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    border-radius: 10em !important;
    transition: all 0.4s ease-in-out !important;
    // background-color: #ffffff !important;
    & > button,
    div {
      height: 100% !important;
      width: 100% !important;
      &:hover,
      &:focus,
      &:active {
        outline: none !important;
      }
      &:first-child {
        border-radius: 6em 0 0 6em !important;
      }
      &:last-child {
        border-radius: 0 6em 6em 0 !important;
      }
    }
    & > button {
      min-width: 33% !important;
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_warn{
      border: solid 1px #ffcc00;
      transition: all 0.4s ease-in-out !important;
      & > input {
        outline-color: #ffcc00 !important;
      }
    }
    &_dang{
      border: solid 1px #ee5050 !important;
      transition: all 0.4s ease-in-out !important;
      & > input {
        outline-color: #ee5050 !important;
      }
    }
    &_number {
      background-color: #ffffff !important;
      border: none !important;
      width: 100% !important;
      height: 100% !important;
      text-align: center !important;
    }
  }
  
  .onlyNumber {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    align-items: center;
    justify-content: center;
    &_circle {
      border-radius: 50% !important;
      background-color: #23a1db !important;
      color: #ffffff !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .inputContainer {
      display: flex;
      width: 100%;
      height: 100% !important;
      align-items: center;
      justify-content: center;
  }
  