.modalDetail {
    &_header {
      color: #64707a !important;
      font-size: 24px !important;
      text-align: left !important;
    }
    &_appBar {
      box-shadow: 0 1px 6px #00000029 !important;
      z-index: 1101 !important;
    }
  }