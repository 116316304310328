.img-responsive {
  height: auto !important;
}

.packing-list {
  font-size: 14px;
}

.packing-input {
  min-width: 100px;
    border: 0;
    height: 30px;
    border-radius: 5px;
    min-width: 200px !important;
}

.qty-input {
    border: 0;
    background-color: #dddddd;
    height: 30px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
}

.avatar-square {
  border-radius: 0 !important;
}

.avatar-container {
  height: 64px !important;
  width: 64px !important;
}