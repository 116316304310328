.check-out__paper {
  padding: 25px;
  height: 100%;
}

.check-out__key-board-container {
  padding: 25px;
  height: 50vh;
}

.check-out__key-board-number {
  width: 100%;
  height: 100%;
  padding: 25px !important;
  border: solid 1px !important;
}

.check-out__money-input-container {
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  padding: 5px 0px;
}

.check-out__money-input-input {
  width: 25vw !important;
  font-size: 25px !important;
  @media screen and (max-width: 425px) {
    & {
      width: 100% !important;
    }

    & input {
      text-align: center;
    }
  }
}

.check-out__item-paper {
  padding: 25px;
}

.check-out__item-scroll {
  max-height: 35vh;
  overflow-y: auto;
}

.checkout {
  &_modal {
    max-width: 34% !important;
  }
}

.modifix-modal {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
}

.modal-content {
  transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
}

.amount {
  &_payable {
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > div {
      border-radius: 8px !important;
      border-radius: inherit;
      height: 50px !important;
    }
    &_symbol {
      height: 100%;
      width: 58px;
      background-color: $color-grey;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 8px 0 0 8px !important;
    }
    &_field {
      font-size: 22px !important;
      color: #000;
      outline: none !important;
      border: none !important;
      background: none;
      text-align: end;
      font-weight: 600;
      height: 100% !important;
      background-color: #f5f9fa;
      border: 0.15em solid #b7bfc6 !important;
      border-radius: 0.6em !important;
      &::before {
        border-bottom: none !important;
      }
      &::after {
        border-bottom: none !important;
      }
      & > input {
        font-size: 2.8vh;
        color: #000;
        padding-right: 0.6em;
        outline: none !important;
        border: none !important;
        background: none;
        text-align: end;
        font-weight: 600;
        height: 80% !important;
      }
    }
    &_fieldDis {
      font-size: 2.8vh;
      color: #000;
      padding-right: 0.6em;
      outline: none !important;
      border: none !important;
      background: none;
      text-align: end;
      font-weight: 600;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &_payable_discount {
    height: 60px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f9fa;
    border-radius: 0.6em 0 0 0.6em;
    & > div {
      border-radius: inherit;
    }
    &_symbol {
      height: 100%;
      width: 60px;
      background-color: $color-grey;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
    &_field {
      font-size: 2.8vh;
      color: #000;
      padding-right: 0.6em;
      outline: none !important;
      border: none !important;
      background: none;
      text-align: end;
      font-weight: 600;
    }
    &_fieldDis {
      font-size: 2.8vh;
      color: #000;
      padding-right: 0.6em;
      outline: none !important;
      border: none !important;
      background: none;
      display: flex;
      font-weight: 600;
      height: 100% !important;
      & > div {
        &::before,
        &::after,
        &:hover:not(.MuiInput-disabled-503):not(.MuiInput-focused-502):not(.MuiInput-error-505):before {
          border-bottom: none;
        }
        & > input {
          text-align: right !important;
          font-size: 1.2em;
          padding-bottom: 0;
        }
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &_method {
    min-height: 6vh !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    & > button {
      margin: 2px 5px;
      outline: none;
      min-width: 140px;
      font-size: 1.3em;
      text-transform: capitalize;
      transition: all 0.1s linear;
      background-color: #fff !important;
      color: $color-grey-heavy !important;
      border: solid $color-grey 1px !important;
      &:hover {
        color: #fff !important;
        transition: all 0.1s linear;
        background-color: $color-black !important;
      }
    }
    .checkout_selected {
      color: #fff !important;
      transition: all 0.1s linear;
      background-color: $color-black !important;
    }
  }
  &_subMethod {
    min-height: 6vh !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    & > button {
      margin: 2px 5px;
      min-width: 140px;
      height: 4.5vh !important;
      outline: none;
      font-size: 1.3em;
      text-transform: capitalize;
      transition: all 0.1s linear;
      background-color: #fff !important;
      color: $color-grey-heavy !important;
      border: solid $color-grey 1px !important;
      &:hover {
        color: #fff !important;
        transition: all 0.1s linear;
        background-color: $color-black !important;
      }
    }
    .sub_checkout_selected {
      color: #fff !important;
      transition: all 0.1s linear;
      background-color: $color-black !important;
    }
  }
  &_registry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.6em 0 0 0.6em;
    &_item {
      display: flex;
      justify-content: space-between;
      background-color: #f5f9fa;
      margin-bottom: 0.4em;
      height: 50px !important;
      & > div {
        display: flex;
        align-items: center;
      }
      & > button {
        outline: none;
      }
      &_method {
        color: #fff;
        font-weight: 700;
        width: 58px !important;
        font-size: 20px;
        background-color: $color-grey;
        border-radius: 8px 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_money {
        font-size: 1.2em;
        font-weight: 600;
        margin-left: auto;
      }
      &_cb {
        margin-left: 1em !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &_tendered {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.1s linear;
    &_total {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &_symbol {
      transition: all 0.1s linear;
      height: 100%;
      width: 58px;
      background-color: $color-grey;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 0.1em 0 0 0.1em;
    }
    &_balance {
      transition: all 0.1s linear;
      &_falta {
        transition: all 0.1s linear;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        border: 0.15em solid $color-warning !important;
        background-color: $color-warning-opacity;
        &_symbol {
          width: 58px;
          height: 100%;
          color: #fff;
          display: flex;
          font-size: 20px;
          align-items: center;
          justify-content: center;
          transition: all 0.1s linear;
          border-radius: 0.1em 0 0 0.1em;
          background-color: $color-warning !important;
        }
      }
      &_cambio {
        display: flex;
        align-items: center;
        transition: all 0.1s linear;
        justify-content: space-between;
        background-color: $color-information-opacity;
        border: 0.15em solid $color-information !important;
        &_symbol {
          width: 58px;
          height: 100%;
          color: #fff;
          display: flex;
          font-size: 20px;
          align-items: center;
          border-radius: 0.1em 0 0 0.1em;
          justify-content: center;
          transition: all 0.1s linear;
          background-color: $color-information !important;
        }
      }
    }
    &_field {
      color: #626c75;
      text-align: end;
      font-weight: 600;
      font-size: 16px;
      background: none;
      padding-right: 0.6em;
      border: none !important;
      outline: none !important;
      &_orange {
        color: #000;
        text-align: end;
        font-weight: 600;
        font-size: 16px;
        background: none;
        padding-right: 0.6em;
        border: none !important;
        outline: none !important;

      }
    }
    & > svg {
      opacity: 0.5;
    }
    & > div {
      height: 50px;
      width: 45% !important;
      border-radius: 0.6em;
      border: 0.15em solid $color-grey;
    }
  }
  &_checkout {
    display: flex;
    align-content: center;
    justify-content: space-between;
    & > button {
      height: 50px !important;
      outline: none;
      font-weight: 600;
      font-size: 1.4em;
      width: 100% !important;
      color: #fff !important;
      transition: 0.4s all linear;
      border-radius: 0.4em;
      background-color: $comercia-purple;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transition: 0.4s all linear;
        background-color: $comercia-purple-disabled;
      }
    }
    #menu-order-estatus-button {
      width: auto !important;
      border-radius: 0 0.4em 0.4em 0 !important;
    }
    &_statuses {
      width: 100% !important;
      margin-top: 0.5em;
      transition: all 0.3s cubic-bezier(0.34, 0, 0.53, 0.99);
      .order_status_selection {
        outline: none;
        width: 4.8vw !important;
        margin: 0 0.5em;
        text-transform: capitalize;
        border: solid $color-grey 0.1em;
        background-color: #fff !important;
        color: $color-grey-heavy !important;
        transition: all 0.3s cubic-bezier(0.34, 0, 0.53, 0.99);
        &:hover {
          width: 6.2vw !important;
          text-transform: capitalize;
          color: $color-black !important;
          border: solid $color-black 0.1em;
          transition: all 0.3s cubic-bezier(0.34, 0, 0.53, 0.99);
        }
        &_selected {
          outline: none;
          width: 6vw !important;
          text-transform: capitalize;
          color: $color-black !important;
          border: solid $color-black 0.11em;
          transition: all 0.3s cubic-bezier(0.34, 0, 0.53, 0.99);
        }
      }
      & > span {
        width: 20%;
        color: #fff;
        display: flex;
        font-size: 1.5em;
        font-weight: 700;
        align-items: center;
        padding: 0.3em 0.4em;
        justify-content: flex-start;
        background-color: $color-grey;
        border-radius: 0.6em 0 0 0.6em;
      }
    }
  }
  &_customer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    min-height: 6vh !important;
    & > div {
      width: 100%;
    }
    .spanBill {
      display: flex;
      color: #b7bfc6;
      font-size: 1.2em;
      font-weight: 500;
      align-items: center;
      justify-content: center;
    }
    .spanBillSelected {
      display: flex;
      color: #8152a0;
      font-size: 1.2em;
      font-weight: 500;
      align-items: center;
      justify-content: center;
    }
  }
  &-empty {
    width: 137px;
    height: 100% !important;
    background-color: #fff !important;
  }
}

.add_register_button {
  & > button {
    outline: none;
    background-color: $color-black;
    color: #fff;
    & > span > svg {
      fill: #fff;
    }
  }
}

.remove_register_button {
  transition: all 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  &:hover,
  &:focus {
    & > span {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transition: all 0.3s ease-in-out;
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
    }
  }
}

#menu-order-estatus {
  z-index: 1999 !important;
}

.checkoutGeneralTitle {
  margin-top: 0.6em;
  font-size: 1.2em;
  font-weight: 500;
  color: $color-grey;
}

.checkoutPayableTitle {
  display: flex;
  justify-content: space-between;
  & > p {
    margin-top: 0.6em;
    font-size: 1.2em;
    font-weight: 500;
    color: $color-grey;
    &:first-child {
      width: 55% !important;
    }
    &:last-child {
      width: 45% !important;
    }
  }
}

.title_grid {
  display: grid;
  grid-template-columns: 55% 45%;
}

/* Layout Bar Menu component */
.method {
  &_list {
    & > div > div {
      max-height: 200px !important;
      overflow-y: auto;
    }
    &_header {
      & > p {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.payments {
  width: 70% !important;
  border-radius: unset !important;
  background-color: #f5f9fa !important;
  border-top: 0.15em solid #b7bfc6 !important;
  border-bottom: 0.15em solid #b7bfc6 !important;
  &_symbol {
    align-items: center;
    background-color: $color-grey;
    color: #fff;
    display: flex;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    max-width: 58px !important;
    width: 100%;
    border-radius: 8px 0 0 8px !important;
  }
  & > div {
    height: 100% !important;
  }
  .paymentType {
    font-size: 16px;
    height: 46px !important;
    outline: none;
    padding-left: 1em;
    text-transform: capitalize;
    transition: all 0.1s linear;
    & > div {
      width: 100%;
      height: 100%;
      line-height: normal !important;
      font-weight: 500;
    }
    &_id {
      color: #000;
      font-size: 18px !important;
      padding-top: 0.2em;
      display: flex;
      align-items: flex-end;
    }
    &_ref {
      font-size: 13px !important;
      padding-left: 1em;
      color: $color-grey-heavy !important;
    }
  }
}
.paymentArrow {
  &_left {
    border-radius: 0 !important;
    border-right: none !important;
  }
  align-items: center;
  background-color: #f5f9fa;
  border-bottom: 0.15em solid #b7bfc6 !important;
  border-radius: 0 0.6em 0.6em 0;
  border-right: 0.15em solid #b7bfc6;
  border-top: 0.15em solid #b7bfc6 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 64px !important;
  height: 100% !important;
  & > button {
    outline: none;
    width: 100%; height: 100%;
    & > svg {
      font-size: 2.4em;
    }
  }
}

/* Discount Modal */

.discount {
  &_total {
    height: 4vh !important;
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
  }
  &_border {
    border-radius: 0.6em;
  }
  &_buttons_group {
    & > button {
      margin: 0 0.5em;
      border-radius: 0.6em;
      height: 6vh !important;
    }
  }
}

@media (max-height: 800px) {
  .paymentType_id {
    font-size: 12px !important;
  }
  .paymentType {
    height: 49px !important;
    padding-left: 0.5em !important;
  }
  .amount_tendered_symbol,
  .amount_tendered_balance_falta_symbol {
    width: 46px !important;
  }
  .payments {
    width: 80% !important;
  }
}

@media (max-height: 500px) {
  .minHeightMobile {
    min-height: 42vh;
    .amount_payable {
      min-height: 10vh !important;
    }
    .amount_tendered {
      min-height: 10vh !important;
      & > div {
        height: 10vh !important;
      }
      &_symbol,
      &_cambio,
      &_field,
      &_balance_falta_symbol {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .amount_method {
    min-height: 10vh !important;
    justify-content: center;
    & > button {
      width: 100% !important;
    }
  }
  .amount_subMethod {
    min-height: 10vh !important;
    justify-content: center;
    & > button {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .amount_checkout_statuses {
    min-height: 4vh !important;
    padding: 0.6em 0;
  }
  .swip {
    display: grid;
    grid-template-columns: 25% 75%;
    & > span {
      width: 100% !important;
    }
    &_container {
      display: flex;
      background-color: #f5f9fa;
      justify-content: space-between;
      & > button {
        outline: none;
      }
    }
    .amount_checkout_swip {
      display: flex;
      font-weight: 600;
      font-size: 1.2em;
      align-items: center;
      color: $color-black;
      width: 100% !important;
      height: 48px !important;
      justify-content: center;
    }
  }
}
