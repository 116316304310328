.listProduct {
  &_btn {
    width: 100% !important;
    height: auto !important;
    background-color: #ffffff !important;
    outline-color: #23A1DB !important;
    display: flex;
    flex-direction: column;
    &_selected {
      background-color: none !important;
    }
  }
  &_container {
    width: 100vw !important;
    height: auto;
    padding: 10px 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      height: 100%;
    }
  }
  &_onDanger {
    background-color: #F05B70 !important;
  }
  &_badge {
    font-family: 'Roboto Medium' !important;
    background-color: #b6bec6;
    color: #ffffff !important;
    min-width: 18px !important;
    height: 24px !important;
    padding: 8px !important;
    font-size: 14px !important;
    z-index: 1 !important;
    border-radius: 20em !important;
    position: absolute !important;
    left: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_image {
    background-color: #efeff4;
    width: 20vw !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 64px !important;
  }
  &_detail {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em !important;
    min-height: 64px !important;
    & > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &:first-child {
        align-items: flex-start;
        text-align: left !important;
      }
      &:last-child {
        text-align: right !important;
        align-items: flex-end;
      }
    }
  }
  &_primary {
    font-size: 16px !important;
    color: #313C52 !important;
    padding-right: 2em !important;
    width: 170px !important;
  }
  &_label {
    font-size: 20px !important;
    font-family: "Roboto Medium";
  }
  &_secondary {
    font-size: 12px !important;
    color: #64707a !important;
  }
  &_footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > span {
      margin-left: 0.4em !important;
    }
    & > div {
      margin-left: 0.4em !important;
    }
  }
}
