.productOption {
  &_modal {
    background-color: #efeff4 !important;
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
  }
  &_header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 54vw !important;
    font-size: 20px !important;
    color: #ffffff !important;
  }
  &_item {
    width: 100% !important;
    height: 94px !important;
    margin-bottom: 1px !important;
    background-color: #ffffff;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    & > div {
      display: flex;
      height: 100% !important;
      line-height: 1.8em !important;
      align-items: center;
    }
    &_button {
      height: auto !important;
      width: 100% !important;
      &:focus,
      &:active,
      &:hover {
        outline: none !important;
      }
    }
    &_btnSelect {
      height: 48px !important;
      width: 48px !important;
      background-color: #23a1db !important;
      border-radius: 50% !important;
      margin-left: auto !important;
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
      &:focus,
      &:active,
      &:hover {
        outline: none !important;
      }
    }
    &_stock {
      justify-content: center;
      flex-direction: column;
      width: 16% !important;
      border-right: solid 1px #efeff4 !important;
      & > span {
        &:first-child {
          color: #313c52 !important;
          font-size: 20px !important;
          font-family: 'Roboto Medium' !important;
        }
      }
    }
    &_select {
      background-color: #f6f6f7 !important;
      border-top: solid 1px #23a1db !important;
      border-bottom: solid 1px #23a1db !important;
    }
    &_detail {
      padding: 0.6em !important;
      justify-content: space-between;
      width: 84% !important;
    }
    &_product {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      & > span {
        margin-top: auto !important;
        font-size: 16px !important;
        text-align: left !important;
      }
    }
    &_action {
      display: flex;
      justify-content: flex-end;
    }
    &_label {
      display: flex;
      flex-direction: column;
      & > span {
        text-align: left !important;
        &:first-child {
          max-width: 98% !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 17px !important;
          font-weight: 450 !important;
        }
        &:last-child {
          color: #64707a !important;
          font-size: 14px !important;
        }
      }
    }
  }
}
